import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pin: {
      position: 'absolute',
      cursor: 'pointer',
      '& svg': {
        color: theme.palette.primary.main,
        borderRadius: '50%',
        fontSize: 24,
        padding: 4,
      },
    },
    pinClick: {
      maxWidth: 300,
      display: 'flex !important',
      cursor: 'default',
      flexDirection: 'column',
      background: '#ffffff',
      fontSize: 14,
      width: 'max-content',
      height: 'auto',
      alignItems: 'start',
      position: 'absolute',
      zIndex: 1000,
      borderRadius: 8,
      boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)',
      top: -5,
      left: 15,
      transform: 'translate(-50%, -100%)',
    },
    roomIcon: {
      backgroundColor: 'inherit !important',
      color: `${theme.palette.primary.main} !important`,
      fontSize: '30px !important',
      padding: '0 !important',
      marginLeft: '-2px',
    },
  })
);

export default useStyles;
