import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { API_HOST } from '../endpoints';
import pubFetcher from '../utils/fetcher';
import { Landlord } from './types';

export interface UseGetAllLandlordsResponse {
  data: Landlord[];
}

export default function useGetAllLandLords(
  queryConfig?: UseQueryOptions<UseGetAllLandlordsResponse>
) {
  const url = `${API_HOST}/api/landlords`;

  return useQuery<UseGetAllLandlordsResponse, Error>(
    [url],
    () => pubFetcher(url),
    queryConfig
  );
}
