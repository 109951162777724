import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { API_HOST } from '../endpoints';
import { PropertyV2 } from '../property/types';
import pubFetcher from '../utils/fetcher';

interface FetcherResponse {
  data: PropertyV2[];
  meta: {
    total: number;
  };
}

export default function useGetLandLordDevelopments(
  landlordId: number,
  queryParams: {
    page?: string;
    pageSize?: string;
    city?: string;
  },
  queryConfig?: UseQueryOptions<any>
) {
  const queryString = Object.keys(queryParams)
    .map((key) => {
      return (
        queryParams[key] &&
        encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
      );
    })
    .join('&');
  const url = `${API_HOST}/api/landlords/${landlordId}/developments?${queryString}`;

  return useQuery<FetcherResponse, Error>(
    [url],
    () => pubFetcher(url),
    queryConfig
  );
}
