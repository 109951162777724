import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: theme.palette.background.paper,
      padding: theme.spacing(2),
      maxWidth: 1170,
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: '100vh',
      },
    },
    developmentContainer: {
      margin: theme.spacing(1, 0),
      '& > .MuiBox-root': {
        marginBottom: theme.spacing(2),
        '& > .MuiCard-root': {
          border: '1px solid #ccc',
          //   borderTop: 0,
          borderRadius: 0,
          '& .MuiCardActionArea-root': {
            padding: theme.spacing(1.25, 2),
          },
        },
      },
    },
  })
);

export default useStyles;
