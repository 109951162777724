import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    heading: {
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightSemiBold,
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(32),
      },
    },
    brandImage: {
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(1.25),
    },
    brandTitle: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightSemiBold,
      fontSize: theme.typography.pxToRem(14),
    },
    listItem: {
      flexDirection: 'column',
      padding: theme.spacing(2),
      borderColor: '#EBEAEA',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 0),
      },
    },
    listHeading: {
      [theme.breakpoints.up('md')]: {
        border: 0,
      },
    },
    alphabetBox: {
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
      flexWrap: 'wrap',

      width: '100%',
    },
    char: {
      flexGrow: 1,

      fontSize: theme.typography.pxToRem(17),
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.gray.contrastText,
      textDecoration: 'none',
      textTransform: 'uppercase',
      '&.active': {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(24),
      },
    },
  })
);

export default useStyles;
