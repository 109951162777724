import * as React from 'react';
import { ListItem, Box } from '@material-ui/core';
import { Link, Typography } from '../../../../atoms';
import useStyles from './BrandsByChar.styles';
import { Landlord } from '../../../../../api/landlords/types';

// ========== TYPES ========== //

type BrandsByCharProps = {
  char: string;
  groupLandlords: Landlord[];
};

// ========== COMPONENT ========== //

export default function BrandsByChar({
  char,
  groupLandlords,
}: BrandsByCharProps) {
  const classes = useStyles();

  return (
    <ListItem
      id={char}
      className={classes.listItem}
      dense
      divider
      alignItems="flex-start"
    >
      <Typography className={classes.char}>{char}</Typography>
      <Box className={classes.brandTitleContainer}>
        {groupLandlords.length > 0 &&
          groupLandlords.map(({ landlord_id, landlord_name }) => (
            <Link
              key={landlord_id}
              className={classes.brandTitle}
              to={`/landlords/${landlord_id}`}
              noColor
            >
              {landlord_name}
            </Link>
          ))}
      </Box>
    </ListItem>
  );
}
