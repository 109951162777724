import * as React from 'react';
import { Box, Grid } from '@material-ui/core';
import BackNavigation from './BackNavigation/BackNavigation';
import { Typography } from '../../../../atoms';
import useStyles from './ModalActiveArea.styles';
import useGetLandLordDevelopments from '../../../../../api/landlords/useGetLandLordDevelopments';
import PropertyCard from '../../../properties/PropertyCard/PropertyCard';
import { PropertyListMobileViewMode } from '../../../properties/PropertyListPage';
import { ScreenType } from '../../../../../config';

// ========== COMPONENT ========== //

export default function ModalActiveArea({
  landlordId = 0,
  area = '',
  closeModal,
}: {
  landlordId: number;
  area: string;
  closeModal: React.MouseEventHandler<HTMLSpanElement>;
}) {
  const classes = useStyles();
  const { data: landlordDevelopmentsResponse } = useGetLandLordDevelopments(
    landlordId,
    { city: area },
    {
      enabled: !!landlordId && !!area,
    }
  );

  const landlordDevelopments = landlordDevelopmentsResponse?.data;

  return (
    <Box className={classes.container}>
      <BackNavigation title={area} onClick={closeModal} />
      {landlordDevelopmentsResponse?.meta?.total && (
        <Typography>
          {landlordDevelopmentsResponse?.meta?.total > 1
            ? `Showing all ${landlordDevelopmentsResponse?.meta?.total} developments`
            : `Showing all developments`}
        </Typography>
      )}
      <Box className={classes.developmentContainer}>
        <Grid container spacing={2}>
          {landlordDevelopments?.map((property) => (
            <Grid item xs={12} sm={6} md={4} key={`${property.development_id}`}>
              <PropertyCard
                key={`${property.development_id}`}
                property={property}
                propertyListMobileViewMode={PropertyListMobileViewMode.WIDE}
                screenType={ScreenType.MOBILE}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
