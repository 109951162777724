import * as React from 'react';
import { Box, List, ListItem } from '@material-ui/core';
import { Link, Typography } from '../../../../atoms';
import clsx from 'clsx';
import useStyles from './FeaturedBrands.styles';
import CloudinaryPicture from '../../../../atoms/CloudinaryPicture/CloudinaryPicture';
import { Landlord } from '../../../../../api/landlords/types';
import { Skeleton } from '@material-ui/lab';

// TODO: replace with actual featured brands when data is available
const placeholderFeaturedLandlords: Pick<
  Landlord,
  'landlord_id' | 'landlord_name' | 'landlord_logo_src'
>[] = [
  {
    landlord_id: 1,
    landlord_name: 'A2Dominion',
    landlord_logo_src:
      'https://storage.googleapis.com/btr-public-files/landlords-logo/1-A2Dominion.png',
  },
  {
    landlord_id: 110,
    landlord_name: 'Folio London',
    landlord_logo_src:
      'https://storage.googleapis.com/btr-public-files/landlords-logo/110-Folio%20London.png',
  },
  {
    landlord_id: 139,
    landlord_name: 'L&Q PRS',
    landlord_logo_src:
      'https://storage.googleapis.com/btr-public-files/landlords-logo/139-L&Q PRS.png',
  },
  {
    landlord_id: 209,
    landlord_name: 'Simple Life',
    landlord_logo_src:
      'https://storage.googleapis.com/btr-public-files/landlords-logo/209-Simple Life.jpg',
  },
];

export default function FeaturedBrands() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <List className={classes.list}>
        <ListItem
          alignItems="center"
          dense
          divider
          className={clsx(classes.listItem, classes.listHeading)}
        >
          <Typography className={classes.heading}>Featured brands</Typography>
        </ListItem>
        {placeholderFeaturedLandlords.map(
          ({ landlord_id, landlord_logo_src, landlord_name }) => (
            <ListItem
              key={`brand-${landlord_id}`}
              alignItems="center"
              dense
              divider
              className={classes.listItem}
            >
              <Box className={classes.brandImage}>
                <Link to={`/landlords/${landlord_id}`}>
                  {landlord_logo_src ? (
                    <CloudinaryPicture
                      imgSrc={landlord_logo_src}
                      imgAlt={landlord_name}
                      config={{
                        '0': {
                          height: 80,
                        },
                      }}
                    />
                  ) : (
                    <Skeleton variant="rect" width={200} height={85} />
                  )}
                </Link>
              </Box>
              <Link
                to={`/landlords/${landlord_id}`}
                className={classes.brandTitle}
              >
                {landlord_name}
              </Link>
            </ListItem>
          )
        )}
      </List>
    </Box>
  );
}
