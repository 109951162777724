import * as React from 'react';
import { Box, Container } from '@material-ui/core';
import SEO from '../../../atoms/SEO';
import { RouteComponentProps } from '@reach/router';
import About from './About';
import clsx from 'clsx';
import ActiveAreas from './ActiveAreas';
import LandlordDevelopments from './LandlordDevelopments';
import useStyles from './LandlordSingleViewPage.styles';
import useGetLandLordById from '../../../../api/landlords/useGetLandLordById';
import useGetLandLordActiveAreas from '../../../../api/landlords/useGetLandLordActiveAreas';
import useScreenType from '../../../../utils/dom/useScreenType';
import { ScreenType } from '../../../../config';

// ========== COMPONENT ========== //

export default function LandlordSingleViewPage({
  landlordId = 0,
}: RouteComponentProps<{
  children: React.ReactNode;
  landlordId: number;
}>) {
  const classes = useStyles();
  const screenType = useScreenType();

  const { data: landlordResponse } = useGetLandLordById(landlordId, {
    enabled: !!landlordId,
  });
  const { data: landlordActiveAreasResponse } = useGetLandLordActiveAreas(
    landlordId,
    {
      enabled: !!landlordId,
    }
  );

  const landlord = landlordResponse?.data;
  const landlordActiveAreas = landlordActiveAreasResponse?.data;

  const scrollToSection: React.MouseEventHandler<HTMLAnchorElement> = (
    event
  ) => {
    event.preventDefault();
    const elementId = event.currentTarget
      .getAttribute('href')
      ?.replace('#', '');
    if (elementId) {
      const sectionElement = document.getElementById(elementId);
      if (sectionElement) {
        const linkCollection =
          event.currentTarget?.parentElement?.children &&
          Array.from(event.currentTarget?.parentElement?.children);
        const linkElementArray = linkCollection ? [...linkCollection] : [];
        linkElementArray.map((child) => child.classList.remove('active'));
        event.currentTarget.classList.add('active');
        window.scrollTo({
          top:
            sectionElement.offsetTop -
            (screenType === ScreenType.DESKTOP ? 80 : 60),
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <>
      <SEO
        title={landlord?.landlord_name ?? `Landlord ${landlordId} - Loading...`}
      />
      <Box className={classes.container}>
        <Box className={classes.navigation}>
          <Container className={classes.contentContainer}>
            <a
              href="#about"
              onClick={scrollToSection}
              className={clsx(classes.buttonAnchor, 'active')}
            >
              About
            </a>
            <a
              href="#active-areas"
              onClick={scrollToSection}
              className={classes.buttonAnchor}
            >
              Active Areas
            </a>
            <a
              href="#developments"
              onClick={scrollToSection}
              className={classes.buttonAnchor}
            >
              Developments
            </a>
          </Container>
        </Box>
        <Container className={classes.contentContainer}>
          {landlord && <About landlord={landlord} />}
          {landlordActiveAreas && (
            <ActiveAreas activeAreas={landlordActiveAreas} />
          )}
          <LandlordDevelopments landlordId={landlordId} />
        </Container>
      </Box>
    </>
  );
}
