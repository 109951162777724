import React, { useState } from 'react';
import { Box, ClickAwayListener } from '@material-ui/core';
import useStyles from './styles/PropertyPin.styles';
import clsx from 'clsx';
import { Room } from '@material-ui/icons';
import { PropertyV2 } from '../../../../../api/property/types';
import PropertyCard from '../../../properties/PropertyCard/PropertyCard';
import { PropertyListMobileViewMode } from '../../../properties/PropertyListPage';
import { ScreenType } from '../../../../../config';

interface ComponentProps {
  lat?: number;
  lng?: number;
  img?: string;
  property: PropertyV2;
}

export default function PropertyPin({ property }: ComponentProps) {
  const classes = useStyles();

  const [isShowInfo, setShowInfo] = useState(false);

  const showPopupInfo = () => {
    setShowInfo(true);
  };

  const closePopupInfo = () => {
    setShowInfo(false);
  };

  return (
    <ClickAwayListener onClickAway={closePopupInfo}>
      <Box className={clsx(classes.pin)}>
        {isShowInfo && (
          <Box className={classes.pinClick}>
            <PropertyCard
              key={`${property.development_id}`}
              property={property}
              propertyListMobileViewMode={PropertyListMobileViewMode.WIDE}
              screenType={ScreenType.MOBILE}
            />
          </Box>
        )}
        <Box onClick={showPopupInfo}>
          <Room className={classes.roomIcon} />
        </Box>
      </Box>
    </ClickAwayListener>
  );
}
