import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2, 0),
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        borderBottom: `1px solid #EBEAEA`,
        padding: theme.spacing(6, 0, 3),
      },
    },
    brandImage: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginRight: 115,
      },
    },
    brandDescription: {
      [theme.breakpoints.up('md')]: {
        flex: 3,
      },
    },
    description: {
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 10,
      '-webkit-box-orient': 'vertical',
      '& p': {
        margin: theme.spacing(0, 0, 2),
      },
      '& strong, & h1, & h2, & h3, & h4, & h5, & h6': {
        fontWeight: theme.typography.fontWeightSemiBold,
      },
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
      '&.active': {
        overflow: 'auto',
        display: 'block',
      },
    },
    readmore: {
      color: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(2),
      },
    },
    websiteButton: {
      marginTop: 'auto',
      fontSize: theme.typography.pxToRem(16),
    },
  })
);

export default useStyles;
