import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { API_HOST } from '../endpoints';
import pubFetcher from '../utils/fetcher';
import { ActiveArea } from './types';

interface FetcherResponse {
  data: ActiveArea[];
  meta: {
    count: number;
  };
}

export default function useGetLandLordActiveAreas(
  landlordId: number,
  queryConfig?: UseQueryOptions<any>
) {
  const url = `${API_HOST}/api/landlords/${landlordId}/activeArea`;

  return useQuery<FetcherResponse, Error>(
    [url],
    () => pubFetcher(url),
    queryConfig
  );
}
