import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3, 0),
      },
    },
    contentContainer: {
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    navigation: {
      [theme.breakpoints.up('md')]: {
        borderBottom: `1px solid #EBEAEA`,
        paddingBottom: theme.spacing(3),
      },
    },
    buttonAnchor: {
      display: 'inline-block',
      padding: theme.spacing(0.5, 2),
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.gray.contrastText}`,
      fontSize: theme.typography.pxToRem(13),
      fontWeight: theme.typography.fontWeightSemiBold,
      color: theme.palette.gray.contrastText,
      textDecoration: 'none',
      marginRight: theme.spacing(2),
      '&.active': {
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(18),
        border: 0,
        padding: 0,
        marginRight: theme.spacing(11),
        '&.active': {
          color: theme.palette.primary.main,
          borderColor: 'transparent',
          backgroundColor: 'transparent',
        },
      },
    },
  })
);

export default useStyles;
