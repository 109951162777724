import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    listItem: {
      backgroundColor: '#F3F3F3',
      marginBottom: 12,
      padding: theme.spacing(0.75, 2),
      '&.MuiListItem-button:hover': {
        backgroundColor: '#fff',
      },
    },
    listItemText: {
      textAlign: 'center',
    },
    link: {
      textDecoration: 'none',
    },
    navigationLink: {
      color: theme.palette.gray.contrastText,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightSemiBold,
      textTransform: 'uppercase',
    },
    icon: {
      fontSize: '1.6rem',
      verticalAlign: 'top',
      color: '#939598',
      pointerEvents: 'none',
    },
    iconLeft: {
      pointerEvents: 'none',
      position: 'absolute',
      marginLeft: theme.spacing(-1),
    },
  })
);

export default useStyles;
