import * as React from 'react';
import { Box } from '@material-ui/core';
import { Typography } from '../../../../atoms';
import clsx from 'clsx';
import { useParams } from '@reach/router';
import useStyles from './ActiveAreas.styles';
import { ActiveArea } from '../../../../../api/landlords/types';
import CloudinaryPicture from '../../../../atoms/CloudinaryPicture/CloudinaryPicture';
import { Modal } from '../../../../molecules';
import ModalActiveArea from '../ModalActiveArea';
import { Skeleton } from '@material-ui/lab';
import cityImages from '../../../../../config/cityImageData/city-images.json';

type ActiveAreasProps = {
  activeAreas: ActiveArea[];
};

// ========== COMPONENT ========== //

export default function ActiveAreas({ activeAreas }: ActiveAreasProps) {
  const classes = useStyles();
  const { landlordId } = useParams();
  const [city, setCity] = React.useState('');
  const onCloseModal = () => setCity('');

  const openModal = (city) => setCity(city);

  return (
    <Box id="active-areas" className={classes.container}>
      <Typography className={classes.heading}>Active areas</Typography>
      <Box className={clsx(classes.areaContainer, 'area-container')}>
        {activeAreas.map(({ image, city }, index) => {
          const cityData = cityImages.find(
            (data) => data.name.toLowerCase() === city.toLowerCase()
          );
          return (
            <Box
              key={`area-${index}`}
              className={classes.area}
              onClick={() => openModal(city)}
            >
              {cityData ? (
                <CloudinaryPicture
                  imgSrc={cityData.img}
                  imgAlt={city}
                  config={{
                    '0': {
                      height: 205,
                    },
                  }}
                />
              ) : (
                <Skeleton variant="rect" height={205} />
              )}
              <Typography>{city}</Typography>
            </Box>
          );
        })}
      </Box>
      <Modal
        open={!!city}
        onClose={onCloseModal}
        modalWidth={1170}
        styleCustomized={false}
        className={classes.modalActiveArea}
      >
        <ModalActiveArea
          landlordId={landlordId}
          area={city}
          closeModal={onCloseModal}
        />
      </Modal>
    </Box>
  );
}
