import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    heading: {
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightSemiBold,
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(32),
      },
    },
    brandImage: {
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(1.25),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(3),
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    brandTitle: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightSemiBold,
      fontSize: theme.typography.pxToRem(14),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(20),
      },
    },
    list: {
      [theme.breakpoints.up('md')]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        justifyItems: 'center',
        alignItems: 'stretch',
      },
    },
    listHeading: {
      [theme.breakpoints.up('md')]: {
        gridColumn: 'span 4',
      },
    },
    listItem: {
      flexDirection: 'column',
      padding: theme.spacing(2),
      borderColor: '#EBEAEA',
      [theme.breakpoints.up('md')]: {
        border: 0,
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  })
);

export default useStyles;
