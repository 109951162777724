import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2, 0),
    },
    totalBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1.25),
    },
    developmentContainer: {
      '& > .MuiBox-root': {
        marginBottom: theme.spacing(2),
        '& > .MuiCard-root': {
          border: '1px solid #ccc',
          height: '100%',
          borderRadius: 0,
          '& .MuiCardActionArea-root': {
            padding: theme.spacing(1.25, 2),
          },
          '&  picture img': {
            display: 'block',
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridColumnGap: theme.spacing(2.5),
        gridRowGap: theme.spacing(2),
      },
    },
    heading: {
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(17),
      fontWeight: theme.typography.fontWeightSemiBold,
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(32),
        marginBottom: theme.spacing(2.5),
      },
    },
    mapViewCtn: {
      width: '100%',
      height: 620,
      position: 'relative',
    },
    mapIcon: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        fontSize: 18,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightSemiBold,
        '& .MuiSvgIcon-root': {
          fontSize: 22,
        },
      },
    },
    resultDevelopments: {
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
  })
);

export default useStyles;
