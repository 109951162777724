import * as React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Typography } from '../../../../../atoms';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import useStyles from './BackNavigation.styles';

export type NavigationInfo = {
  title: string;
  onClick: React.MouseEventHandler<HTMLSpanElement>;
};

interface BackNavigationProps extends NavigationInfo {
  children?: React.ReactNode;
}

export default function BackNavigation({
  title,
  onClick,
}: BackNavigationProps) {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem}>
      <ListItemIcon className={classes.iconLeft}>
        <ArrowLeftIcon className={classes.icon} />
      </ListItemIcon>
      <ListItemText className={classes.listItemText}>
        <Typography onClick={onClick} className={classes.navigationLink}>
          {title}
        </Typography>
      </ListItemText>
    </ListItem>
  );
}
