import * as React from 'react';
import {
  Box,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { Typography } from '../../../../atoms';
import BrandsByChar from '../BrandsByChar/BrandsByChar';
import useStyles from './AllBrands.styles';
import { Landlord } from '../../../../../api/landlords/types';

export interface AllBrandsProps {
  landlords: Landlord[];
}

export default function AllBrands({ landlords }: AllBrandsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const showDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const scrollToBrandsByChar: React.MouseEventHandler<HTMLAnchorElement> = (
    event
  ) => {
    event.preventDefault();
    const elementId = event.currentTarget
      .getAttribute('href')
      ?.replace('#', '');
    if (elementId) {
      const brandElement = document.getElementById(elementId);
      if (brandElement) {
        const linkCollection =
          event.currentTarget?.parentElement?.children &&
          Array.from(event.currentTarget?.parentElement?.children);
        const linkElementArray = linkCollection ? [...linkCollection] : [];
        linkElementArray.map((child) => child.classList.remove('active'));
        event.currentTarget.classList.add('active');
        const offset =
          brandElement.offsetTop +
          (brandElement.parentElement?.offsetTop ?? 0) -
          (showDesktop ? 80 : 60);

        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    }
  };

  const groupLandlords: Record<string, Landlord[]> = {};

  landlords.forEach((landlord) => {
    let firstChar = landlord.landlord_name[0].toUpperCase();

    if (!/[A-Z]/i.test(firstChar)) {
      firstChar = '#';
    }

    if (groupLandlords[firstChar]) {
      groupLandlords[firstChar].push(landlord);
    } else {
      groupLandlords[firstChar] = [landlord];
    }
  });

  const groupLandlordKeys = Object.keys(groupLandlords);
  groupLandlordKeys.sort();

  return (
    <Box className={classes.container}>
      <List>
        <ListItem
          alignItems="center"
          dense
          divider
          className={clsx(classes.listItem, classes.listHeading)}
        >
          <Typography className={classes.heading}>All brands</Typography>
        </ListItem>

        <ListItem
          alignItems="center"
          dense
          divider
          className={classes.listItem}
        >
          <Box className={classes.alphabetBox}>
            {groupLandlordKeys.map((char) => (
              <a
                key={char}
                href={`#${char}`}
                className={classes.char}
                onClick={scrollToBrandsByChar}
              >
                {char}
              </a>
            ))}
          </Box>
        </ListItem>
        {groupLandlordKeys.map(
          (char) =>
            groupLandlords[char] && (
              <BrandsByChar
                key={char}
                char={char}
                groupLandlords={groupLandlords[char]}
              />
            )
        )}
      </List>
    </Box>
  );
}
