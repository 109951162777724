import { Fab, makeStyles, Zoom, createStyles } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    backTop: {
      fontWeight: 'bold',
      position: 'fixed',
      bottom: 30,
      right: 15,
      zIndex: 1,
      padding: 0,
      borderRadius: 0,
    },
  })
);

const BackTop = () => {
  const [show, setShow] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = () => {
    setShow(window.scrollY > 0);
  };

  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Zoom
      in={show}
      exit={true}
      timeout={500}
      style={{
        transitionDelay: `200ms`,
      }}
      unmountOnExit
    >
      <Fab
        id="back-top"
        onClick={onClick}
        color="primary"
        variant="extended"
        className={classes.backTop}
      >
        <KeyboardArrowUp />
      </Fab>
    </Zoom>
  );
};

export default BackTop;
