import * as React from 'react';
import { useState } from 'react';
import { Box } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ListIcon from '@material-ui/icons/List';
import clsx from 'clsx';
import { Typography } from '../../../../atoms';
import { Pagination } from '../../../../molecules';
import useStyles from './styles/LandlordDevelopments.styles';
import {
  combineQueryParams,
  parseQueryParamsString,
} from '../../../../../utils/url';
import { getUrlSearchString, navigate } from '../../../../../utils/dom';
import GoogleMapReact from 'google-map-react';
import PropertyPin from './PropertyPin';
import useGetLandLordDevelopments from '../../../../../api/landlords/useGetLandLordDevelopments';
import PropertyCard from '../../../properties/PropertyCard/PropertyCard';
import { PropertyListMobileViewMode } from '../../../properties/PropertyListPage';
import { ScreenType } from '../../../../../config';
import useScreenType from '../../../../../utils/dom/useScreenType';

type LandlordDevelopmentsProps = {
  landlordId: number;
};

// ========== COMPONENT ========== //

export default function LandlordDevelopments({
  landlordId,
}: LandlordDevelopmentsProps) {
  const classes = useStyles();
  const [toggleMapView, setToggleMapView] = useState(false);
  const pageSize = '16';
  const queryString = getUrlSearchString(true);
  let queryParams: Record<string, string> = {};
  if (queryString) {
    queryParams = parseQueryParamsString(queryString);
  }

  const { page = '1' } = queryParams;
  const { data: landlordDevelopmentsResponse, isSuccess } =
    useGetLandLordDevelopments(
      landlordId,
      { page, pageSize: toggleMapView ? undefined : pageSize },
      {
        enabled: !!landlordId,
      }
    );
  const properties = landlordDevelopmentsResponse?.data;
  const total = landlordDevelopmentsResponse?.meta.total ?? 0;
  const totalPages = Math.ceil(total / parseInt(pageSize));
  const search = getUrlSearchString(true);
  const screenType = useScreenType();

  React.useEffect(() => {
    if (queryString && isSuccess) {
      scrollTop();
    }
  }, [queryString, isSuccess]);

  const handlePagination = (action: string, pageAction?: number) => {
    let urlComponents = {};
    let numberPage: number = parseInt(page);
    switch (action) {
      case 'next':
        numberPage = numberPage + 1;
        break;
      case 'prev':
        numberPage = numberPage - 1;
        break;
      case 'start':
        numberPage = 1;
        break;
      case 'end':
        numberPage = totalPages;
        break;
      case 'page_action':
        if (pageAction) numberPage = pageAction;
        break;
      default:
        numberPage;
        break;
    }
    if (page) {
      const arrayParamsSearch = search.split('&');
      arrayParamsSearch.splice(arrayParamsSearch.indexOf(`page=${page}`), 1);
      urlComponents = {
        page: numberPage,
      };
      const urlQueryString = combineQueryParams(urlComponents);
      void navigate(
        `/landlords/${landlordId}?${arrayParamsSearch.join('&')}${
          arrayParamsSearch.length > 0 ? '&' : ''
        }${urlQueryString}`
      );
    } else {
      urlComponents = {
        page: numberPage,
      };
      const urlQueryString = combineQueryParams(urlComponents);
      void navigate(`/landlords/${landlordId}?${search}&${urlQueryString}`);
    }
  };

  const paginationProps = {
    total,
    page: parseInt(page),
    itemsPerPage: parseInt(pageSize),
    handlePagination,
  };

  function handleToggleMapView() {
    setToggleMapView(!toggleMapView);
  }

  const pins =
    properties?.length !== 0 &&
    properties?.map((property) => {
      const { lat, lng } = property.geographyJson;

      return (
        <PropertyPin
          key={`${lat}${lng}:${property.development_id}`}
          lat={lat}
          lng={lng}
          property={property}
        />
      );
    });

  const mapRef = React.useRef();
  const mapsRef = React.useRef();

  const mapConfig = {
    center: properties ? properties[0].geographyJson : { lat: 0, lng: 0 },
    zoom: 10,
    hoverDistance: 24,
  };

  // https://github.com/google-map-react/google-map-react/blob/master/API.md#options-funcobject
  const mapOptions = {
    // https://developers.google.com/maps/documentation/javascript/controls#Adding_Controls_to_the_Map
    // Also note that Street View panoramas generated via this Street View
    // Pegman control are not billed, but Street View panoramas created with
    // the StreetViewPanorama object are billed.
    // https://developers.google.com/maps/documentation/javascript/streetview#StreetViewPanoramas
    // https://developers.google.com/maps/documentation/javascript/usage-and-billing#dynamic-street-view
    streetViewControl: true,
  };

  // We can access Google Maps' map and maps objects via the onGoogleApiLoaded
  // prop.
  // https://github.com/google-map-react/google-map-react#use-google-maps-api
  const handleApiLoaded = ({ map, maps }) => {
    // Store the Map and Maps objects in their respective React Refs
    mapRef.current = map;
    mapsRef.current = maps;
  };

  const scrollTop = () => {
    const sectionElement = document.getElementById('developments');
    if (sectionElement) {
      const top =
        sectionElement.offsetTop -
        (screenType === ScreenType.DESKTOP ? 80 : 60);

      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box id="developments" className={classes.container}>
      <Typography className={classes.heading}>Developments</Typography>
      <Box className={classes.totalBox}>
        <Typography className={classes.resultDevelopments}>
          {/* Showing all {total} developments */}
        </Typography>
        <Typography className={classes.mapIcon} onClick={handleToggleMapView}>
          {toggleMapView ? (
            <>
              <ListIcon style={{ marginRight: '0.25rem' }} />
              LIST VIEW
            </>
          ) : (
            <>
              <LocationOnIcon style={{ marginRight: '0.25rem' }} />
              MAP VIEW
            </>
          )}
        </Typography>
      </Box>
      {properties &&
        (toggleMapView ? (
          <Box className={classes.mapViewCtn}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.GATSBY_GOOGLE_MAPS_API_KEY ?? '',
                libraries: ['places'],
                language: 'en-GB',
                region: 'GB',
              }}
              {...mapConfig}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={handleApiLoaded}
              options={mapOptions}
            >
              {pins}
            </GoogleMapReact>
          </Box>
        ) : (
          <Box>
            <Box
              className={clsx(
                classes.developmentContainer,
                'development-container'
              )}
            >
              {properties.length > 0 &&
                properties.map((property) => (
                  <PropertyCard
                    key={`${property.development_id}`}
                    property={property}
                    propertyListMobileViewMode={PropertyListMobileViewMode.WIDE}
                    screenType={ScreenType.MOBILE}
                  />
                ))}
            </Box>
            <Pagination {...paginationProps} />
          </Box>
        ))}
    </Box>
  );
}
