import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalActiveArea: {
      background: '#EBEAEA',
      overflow: 'auto',
      height: '100vh',
    },
    container: {
      padding: theme.spacing(2, 0),
      [theme.breakpoints.up('md')]: {
        borderBottom: `1px solid #EBEAEA`,
        padding: theme.spacing(3, 0),
      },
    },
    heading: {
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(17),
      fontWeight: theme.typography.fontWeightSemiBold,
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(32),
        marginBottom: theme.spacing(2.5),
      },
    },
    areaContainer: {
      overflow: 'hidden',
      height: 'auto',
      [theme.breakpoints.up('md')]: {
        height: 'auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridColumnGap: theme.spacing(2.5),
        gridRowGap: theme.spacing(2),
      },
    },
    area: {
      position: 'relative',
      cursor: 'pointer',
      marginBottom: theme.spacing(1.25),
      '& img': {
        width: '100%',
        height: 205,
        objectFit: 'cover',
        display: 'block',
      },
      '& p': {
        backgroundColor: 'rgba(0, 0, 0, 0.33)',
        position: 'absolute',
        top: 0,
        left: 0,
        margin: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: theme.typography.fontWeightSemiBold,
        fontSize: theme.typography.pxToRem(17),
        color: theme.palette.primary.contrastText,
      },
      [theme.breakpoints.up('md')]: {
        margin: 0,
      },
      [theme.breakpoints.down('sm')]: {
        '& img': {
          width: '100%',
          height: 'auto',
          maxHeight: 250,
          objectFit: 'cover',
          display: 'block',
        },
      },
    },
  })
);

export default useStyles;
