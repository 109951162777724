import { makeStyles, Theme, createStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    char: {
      fontSize: theme.typography.pxToRem(17),
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(24),
        flex: 1,
        textAlign: 'center',
      },
    },
    listItem: {
      flexDirection: 'column',
      padding: theme.spacing(2),
      '&:last-child': {
        border: 0,
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(3, 0),
      },
    },
    brandTitleContainer: {
      flex: 5,
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: '1rem',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
    },
    brandTitle: {
      fontSize: theme.typography.pxToRem(14),
      paddingBottom: theme.spacing(1),
      color: '#414141',
      '&:hover': {
        textDecoration: 'underline',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(16),
        padding: theme.spacing(1, 0),
      },
    },
  })
);

export default useStyles;
