import * as React from 'react';
import { Router } from '@reach/router';
import GenericPage from '../../components/layout/CommonLayout/GenericPage';
import {
  LandlordListPage,
  LandlordSingleViewPage,
} from '../../components/pages/landlords';
import ClientOnly from '../../components/atoms/ClientOnly/ClientOnly';
import useAnalyticsSessionData from '../../hooks/useAnalyticsSessionData';

export default function Landlords() {
  useAnalyticsSessionData();

  return (
    <GenericPage>
      <ClientOnly>
        <Router basepath="/landlords">
          <LandlordListPage path="/" />
          <LandlordSingleViewPage path="/:landlordId" />
        </Router>
      </ClientOnly>
    </GenericPage>
  );
}
