import * as React from 'react';
import { Box, Button } from '@material-ui/core';
import MarkdownDisplay from '../../../../atoms/MarkdownDisplay/MarkdownDisplay';
import clsx from 'clsx';
import useStyles from './About.styles';
import { Landlord } from '../../../../../api/landlords/types';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

export interface AboutProps {
  landlord: Landlord;
}

export default function About({ landlord }: AboutProps) {
  const classes = useStyles();
  const [readMore, setReadMore] = React.useState(false);

  const image =
    landlord.landlord_logo_src ?? 'https://dummyimage.com/316x91/f3f3f3/bbb';
  const description = landlord.landlord_long_description;

  const handleReadMore = () => {
    setReadMore(!readMore);
  };

  return (
    <Box id="about" className={classes.container}>
      <Box className={classes.brandImage}>
        <img src={image} alt="landlord logo" />
        {landlord.landlord_website_url && (
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            className={classes.websiteButton}
            href={landlord.landlord_website_url}
          >
            Website
          </Button>
        )}
      </Box>
      <Box className={classes.brandDescription}>
        <Box className={clsx(classes.description, readMore && 'active')}>
          <MarkdownDisplay markdownContent={description ?? ''} />
        </Box>
        {description && (
          <Box className={classes.readmore} onClick={handleReadMore}>
            {readMore ? (
              <>
                Hide <ArrowDropUp />
              </>
            ) : (
              <>
                Read more <ArrowDropDown />
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
