import * as React from 'react';
import { Breadcrumbs, Container, Hidden } from '@material-ui/core';
import { Link, RouteComponentProps } from '@reach/router';
import SEO from '../../../atoms/SEO';
import { Typography } from '../../../atoms';
import AllBrands from './AllBrands';
import useStyles from './LandlordListPage.styles';
import useGetAllLandLords from '../../../../api/landlords/useGetAllLandLords';
import FeaturedBrands from './FeaturedBrands';
import BackTop from './BackTop';

export default function LandlordListPage(props: RouteComponentProps) {
  const classes = useStyles();

  const { data: landlordsResponse } = useGetAllLandLords();

  return (
    <>
      <SEO title="Landlords" />
      <Container className={classes.container}>
        <Hidden mdDown>
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
            <Link color="inherit" to="/" className={classes.noUnderline}>
              <Typography
                color="gray"
                colorVariant="dark"
                component="span"
                className={classes.breadcrumbText}
              >
                Home
              </Typography>
            </Link>
            <Typography
              color="yellow"
              colorVariant="contrastText"
              component="span"
              className={classes.breadcrumbText}
            >
              Landlords
            </Typography>
          </Breadcrumbs>
        </Hidden>

        <FeaturedBrands />

        {landlordsResponse?.data && (
          <AllBrands landlords={landlordsResponse.data} />
        )}
      </Container>
      <BackTop />
    </>
  );
}
