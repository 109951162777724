import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    breadcrumbs: {
      padding: theme.spacing(4, 2, 0),
    },
    breadcrumbText: {
      fontSize: theme.typography.pxToRem(20),
    },
    noUnderline: {
      textDecoration: 'none',
    },
  })
);

export default useStyles;
